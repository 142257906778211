.avia-msie #top{
    .footer--nav{
        padding-top: 130px !important;
    }
    .home--resources-slider{
        .previous{
            transform: translateX(-300%) translateY(-50%) !important;
        }
        .next{
            transform: scaleX(-1) translateX(-200%) translateY(-50%) !important;
        }
    }
    .homepage--testimonial-slider{
        .previous{
            transform: rotate(90deg) !important;
        }
        .next{
            transform: rotate(-90deg) !important;
            top: 45% !important;
        }
    }
    .numbered-columns{
        .flex_column{
            width: 23.5%;
        }
    }
    .champions--benefits-icon-second-row{
        .flex_column{
            .av-special-heading{
                h4{
                    width: 350px;
                    margin: auto;
                }
            }
        }
    }
    .homepage--news-slider{
        .entry-title, .entry-excerpt{
            width: 100%;
        }
    }
    .icon-row-champions{
        .flex_column{
            display: flex;
        }
    }
}